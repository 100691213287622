@import "config.scss";

// scss-lint:disable all

@font-face {
  font-family: "Lindholmen icons";
  src:url("../../fonts/icomoon.eot");
  src:url("../../fonts/icomoon.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/icomoon.ttf") format("truetype"),
    url("../../fonts/icomoon.woff") format("woff"),
    url("../../fonts/icomoon.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

.icon:before,
.icon-before:before,
.icon a:before {
  /*display: inline-block;*/
  font-family: "Lindholmen icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-before *,
.icon-after * {
  display: inline;
}

.icon-before--pin:before {
  content: "\e608";
  width: 0.833333333em;
  text-align: center;
  margin-right: 5px;
}

.icon-before--clock:before {
  content: "\e60a";
  width: 0.833333333em;
  text-align: center;
  margin-right: 5px;
}

.icon--external:before {
  content: "\e610";
  font-size: 0.692307692em; /* ≈ 18px */
}

.icon--arrow-right:before {
  content: "\e60d";
  font-size: 30px;
}

.icon span {
  display: none;
}

.icon--burger:before {
  content: "\e607";
}

.icon--lunch:before {
  content: "\e609";
  font-size: 1.388888889em;
  position: relative;
  top: 4px;
  font-weight: normal;
}
.icon--search:before {
  content: "\e605";
  font-size: 1.388888889em;
  position: relative;
  top: 4px;
  font-weight: normal;
}

.icon--phone a:before {
  content: "\e6111";
  padding-right: .722222222em;
  font-size: .888888889em; /* ≈ 16px */
}

.icon--email a:before {
  content: "\e612";
  padding-right: .722222222em;
  font-size: .888888889em; /* ≈ 16px */
}

.icon--company:before {
  content: "\e613";
  font-size: 1.388888889em;
  position: relative;
  top: 4px;
}

.icon--twitter:before {
  content: "\e601";
  padding-right: .722222222em;
  font-size: .888888889em; /* ≈ 16px */
}

.icon--fb:before {
  content: "\e604";
  padding-right: .722222222em;
  font-size: .888888889em; /* ≈ 16px */
}

.icon--mnd:before {
  content: "\e602";
  padding-right: .722222222em;
  font-size: .888888889em; /* ≈ 16px */
}

.icon--linkedin:before {
  content: "\e603";
  padding-right: .722222222em;
  font-size: .888888889em; /* ≈ 16px */
}

.icon--youtube:before {
  content: "\e600";
  padding-right: .722222222em;
  font-size: .888888889em; /* ≈ 16px */
}

.icon--map:before {
  content: "\e614";
  color: #bbb;
  font-size: 1.388888889em; /* ≈ 25px */
}
@media only screen and (min-width: 768px) {
  .icon--map:before {
    content: "";
    padding-right: 0;
  }
  .icon--map span {
    display: block;
  }
}

.icon--twitter:hover,
.icon--fb:hover,
.icon--mnd:hover,
.icon--linkedin:hover,
.icon--youtube:hover {
  text-decoration: none;
}

.icon--twitter span,
.icon--fb span,
.icon--mnd span,
.icon--linkedin span,
.icon--youtube span {
  display: inline;
}

.icon-header {
  font-weight: normal;
  font-size: 1.222222222em;
  line-height: 1em;
  border-bottom: 1px solid #ebebeb;
  padding: 8px 0;
  margin-bottom: 1em;
}
.icon-header:before {
  font-family: "Lindholmen icons";
  color: $icon-header;
  margin-right: 10px;
  position: relative;
  top: 2px;
}

.icon-header--events:before {
  content: "\e60b";
}

.icon-header--company {
  color: #333;
}
.icon-header--company:before {
  content: "\e613";
}

.icon-header--restaurant {
  color: #333;
}
.icon-header--restaurant:before {
  content: "\e609";
}

.icon-header--news {
}
.icon-header--news:before {
  content: "\e606";
}
.icon-header--lightbulb:before {
  content: "\e606";
}

.icon-header--page-title {
  font-weight: 300;
  font-size: 2.555555556em;
}

// scss-lint:enable all
